<template>
  <div>
    <ul>
      <li v-for="value in values" :key="value">{{ computeForValue(value) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      values: [1, 2, 3, 4, 5, 6],
    };
  },
  computed: {
    computedFn() {
      console.log('Computed function');
      // Doing some expensive calculations
      return Math.PI;
    },
  },
  methods: {
    computeForValue(value) {
      console.log('Computation for value', value);
      return value * this.computedFn;
    },
  },
};
</script>
